import Joi from "joi";
import _ from "lodash";

const customFieldSchema = Joi.object({
  fieldName: Joi.string().allow(null, ""),
  key: Joi.string(),
});

const validateUniqueness = (value, helpers) => {
  const fieldNames = value.emailBodyParagraphs.map((item) => item.fieldName);
  const allFields = [...fieldNames, value.emailSubject];

  const validFields = allFields.filter(
    (field) => field != null && field !== ""
  );

  const duplicates = _(validFields)
    .countBy()
    .pickBy((count) => count > 1)
    .keys()
    .value();

  if (duplicates.length > 0) {
    return helpers.error("object.unique", {
      duplicates: duplicates.join(", "),
    });
  }

  return value;
};

const validateSequentialParagraphs = (value, helpers) => {
  const emailBodyParagraphs = value.emailBodyParagraphs;
  let hasMissingParagraphs = false;
  for (let i = 0; i < emailBodyParagraphs.length; i++) {
    const expectedKey = `paragraph${i + 1}`;
    if (emailBodyParagraphs[i].key !== expectedKey) {
      return helpers.error("array.sequential", {
        index: i + 1,
        expected: expectedKey,
        received: emailBodyParagraphs[i].key,
      });
    }

    const isFieldMissing = emailBodyParagraphs[i].fieldName == null;
    if (!isFieldMissing && hasMissingParagraphs) {
      return helpers.error("array.continuous", {
        index: i + 1,
      });
    }

    if (isFieldMissing) {
      hasMissingParagraphs = true;
    }
  }
  return value;
};

const emailObjectSchema = Joi.object({
  emailBodyParagraphs: Joi.array().items(customFieldSchema),
  emailSubject: Joi.string().allow(null, ""),
})
  .custom(validateUniqueness, "Unique fields validation")
  .custom(validateSequentialParagraphs, "Sequential paragraphs validation")
  .messages({
    "object.unique": `Custom field ({{#duplicates}}) is already in use. Please choose a different field or remove the duplicate.`,
    "array.sequential": `Paragraph {{#index}} has incorrect key. Expected "{{#expected}}", received "{{#received}}".`,
    "array.continuous": `Paragraph {{#index}} cannot be filled in when previous paragraphs are empty.`,
  });

export const outreachFormSchema = Joi.object({
  account: emailObjectSchema,
  prospect: emailObjectSchema,
});
