import React, { useEffect, useCallback } from "react";
import AuthService from "../user/AuthService";
import { makeStyles } from "@mui/styles";
import {
  InputAdornment,
  Card,
  Stack,
  Collapse,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useUser, useUserUpdate } from "../context/UserContext";
import CircularProgress from "@mui/material/CircularProgress";
import JwtService from "./JwtService";
import { detRoles } from "../det/DetMainPage";
import rule5logo from "../res/r5_logo_color.svg";
import {
  StyledTextfield,
  StyledLabel,
  ErrLabel,
} from "../common/StyledComponents";
import { PRICING_PAGE } from "./Registration";
import ThirdPartyAuthButton from "./ThirdPartyAuth";

const Joi = require("joi");

const emailSchema = Joi.string().email({ tlds: { allow: false } });

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
  },
  topBar: {
    padding: "20px",
    float: "right",
  },
  formContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    WebkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  formCard: {
    borderRadius: "10px",
    width: 400,
    boxSizing: "border-box",
    padding: 22,
    boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: "24px",
    height: "94px",
    display: "flex",
  },
  crmButton: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  content: {
    marginTop: "30px",
  },
  username: {
    width: "320px",
  },
  password: {
    width: "320px",
  },
  visAdorn: {
    backgroundColor: "black",
  },
  formMessageNormal: {
    opacity: 0.5,
  },
  formMessageError: {
    color: "red",
  },
  spinner: {
    margin: 58,
  },
  link: {
    textDecoration: "underline 0.1em rgba(0,0,0,0)",
    fontWeight: 500,
    color: "rgb(25, 118, 210)",
    transition: "text-decoration-color 0.2s",
    "&:hover": {
      textDecorationColor: "rgb(25, 118, 210)",
    },
    "&:active": {
      color: "rgba(0,0,0,0.5)",
    },
  },
  linkStyle: {
    fontSize: "13px",
  },
}));

function getRoleMain(userRole) {
  return detRoles.includes(userRole) ? "/det/managecards" : "/main";
}

export default function SignIn(props) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const updateUserContext = useUserUpdate();
  const user = useUser();

  const searchParams = new URLSearchParams(location.search);
  const defaultError = searchParams.get("error");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [formMessageClass, setFormMessageClass] = React.useState(
    classes.formMessageNormal
  );
  const [formMessage, setFormMessage] = React.useState(
    "or use your email to sign in"
  );
  const [loading, setLoading] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [sfdcStatus, setSfdcStatus] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(defaultError || "");
  const [pageReady, setPageReady] = React.useState(false);

  const pushToMain = useCallback(
    (userRole) => {
      history.push(
        location.state?.referrer
          ? location.state.referrer
          : getRoleMain(userRole)
      );
    },
    [history, location.state?.referrer]
  );

  useEffect(() => {
    let token = JwtService.getToken();
    if (token) {
      pushToMain(user.role);
    }
    setPageReady(true);
  }, [pushToMain, user]);

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const submitLogin = (event) => {
    event.preventDefault();
    if (emailSchema.validate(email).error) {
      // setFormMessageClass(classes.formMessageError);
      setErrorMessage("Invalid email address.");
    } else if (password.length < 8) {
      // setFormMessageClass(classes.formMessageError);
      setErrorMessage("Password must be at least 8 characters.");
    } else {
      setLoading(true);
      setSubmitDisabled(true);
      setTimeout(() => {
        AuthService.login(email.toLowerCase(), password).then((response) => {
          setLoading(false);
          if (response.code === "INVALID_ARGUMENT") {
            setErrorMessage(response.message);
          } else if (
            response.code === "INVALID_CREDENTIALS" ||
            response.code === "NOT_FOUND"
          ) {
            setErrorMessage("Invalid email or password.");
          } else if (
            response.code === "EMAIL_NOT_VERIFIED" ||
            response.code === "FORBIDDEN"
          ) {
            setErrorMessage("Email account has not been verified.");
          } else if (response.accessToken) {
            // Successful login.
            // Check if signing in for extension.
            let searchParams = new URLSearchParams(document.location.search);
            let referrer = searchParams.get("referrer");
            if (referrer === "extension") {
              // Close page and notify caller.
              window.opener?.postMessage("AUTH_SUCCESS", "*");
              window.close();
            }
            updateUserContext(response);
          } else {
            setErrorMessage(response.message);
          }
          setSubmitDisabled(false);
        });
      }, 800);
    }
  };

  const handleEmailChange = (e) => {
    setSfdcStatus("");
    setEmail(e.target.value.trim());
    setFormMessage("or use your email to sign in");
    setFormMessageClass(classes.formMessageNormal);
  };

  const handlePasswordChange = (e) => {
    setErrorMessage("");
    setSfdcStatus("");
    setPassword(e.target.value);
    setFormMessage("or use your email to sign in");
    setFormMessageClass(classes.formMessageNormal);
  };

  const isSfdcLanding = location.state?.referrer?.includes("companyName");

  return (
    <div className={classes.container}>
      {pageReady ? (
        <form className={classes.formContainer} onSubmit={submitLogin}>
          <Card className={classes.formCard} variant="outlined">
            <Stack className={classes.content} alignItems="center">
              <div className={classes.header}>
                <img
                  src={rule5logo}
                  alt="logo"
                  style={{ height: "50px", marginTop: "8px" }}
                />
              </div>
              <Box sx={{ width: "320px" }}>
                <ThirdPartyAuthButton
                  type="sfdc"
                  statusUpdateFunction={setSfdcStatus}
                />
                <ThirdPartyAuthButton
                  type="google"
                  statusUpdateFunction={setSfdcStatus}
                />
                <ThirdPartyAuthButton
                  type="okta"
                  statusUpdateFunction={setSfdcStatus}
                />
              </Box>
              <Collapse
                in={sfdcStatus?.trim().length > 0}
                sx={{ textAlign: "center", width: "96%" }}
              >
                <ErrLabel sx={{ mt: 1 }}>{sfdcStatus}</ErrLabel>
              </Collapse>
              {/* </span>
              </Tooltip> */}
              {!isSfdcLanding && (
                <Stack alignItems="center" sx={{ width: "320px" }}>
                  <p className={formMessageClass} style={{ fontSize: "14px" }}>
                    {formMessage}
                  </p>
                  {loading ? (
                    <CircularProgress className={classes.spinner} />
                  ) : (
                    <React.Fragment>
                      <StyledLabel
                        sx={{
                          width: "320px",
                          pl: 1,
                          mt: 1,
                          boxSizing: "border-box",
                        }}
                      >
                        Email
                      </StyledLabel>
                      <StyledTextfield
                        value={email}
                        className={classes.username}
                        label="Email"
                        variant="outlined"
                        margin="dense"
                        onChange={handleEmailChange}
                      />
                      <div style={{ width: "320px", display: "flex" }}>
                        <StyledLabel sx={{ ml: 1, mt: 2, flexGrow: "1" }}>
                          Password
                        </StyledLabel>
                        <Typography
                          variant="caption"
                          className={classes.linkStyle}
                          sx={{ mt: "14px", mr: 1 }}
                        >
                          <Link
                            className={classes.link}
                            to="/password_reset"
                            tabIndex={-1}
                          >
                            Forgot your password?
                          </Link>
                        </Typography>
                      </div>
                      <StyledTextfield
                        value={password}
                        className={classes.password}
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        autoComplete="off"
                        variant="outlined"
                        // margin="normal"
                        endAdornment={
                          <InputAdornment
                            sx={{
                              backgroundColor: "none",
                              mr: "4px",
                              opacity: 0.5,
                            }}
                            position="end"
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={handlePasswordChange}
                      />
                      <Collapse
                        sx={{ textAlign: "left", width: "86%" }}
                        in={errorMessage !== ""}
                      >
                        <ErrLabel>{errorMessage}</ErrLabel>
                      </Collapse>
                    </React.Fragment>
                  )}
                  <Button
                    disableElevation
                    disabled={
                      email.length === 0 ||
                      password.length === 0 ||
                      submitDisabled
                    }
                    type="submit"
                    sx={{ mt: 4, textTransform: "none" }}
                    variant="contained"
                  >
                    Continue
                  </Button>
                </Stack>
              )}
              {/* <FormControlLabel sx={{ ml: 1 }} control={<Checkbox size="small" />} label={<Typography sx={{ mr: 2, mt: '1px' }} variant="caption">Stay logged in</Typography>} /> */}
              <Typography
                variant="caption"
                sx={{ mt: 3, mb: 2 }}
                className={classes.linkStyle}
              >
                New to rule5?{" "}
                <a href={PRICING_PAGE} className={classes.link}>
                  Sign up here.
                </a>
              </Typography>
            </Stack>
          </Card>
        </form>
      ) : null}
    </div>
  );
}
